import React from "react"
import { navigate } from "gatsby"
import { Form, Row, Col, Button } from 'react-bootstrap'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { valueFilter, allValidationCheck } from './validationCheck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

class CustomForm extends React.Component {
  constructor(props) {
		super(props)

		// mapで回せるよう1次キー無し連想配列に変換
		var settings = []
		for( var key in this.props.formSettings ){
			settings.push(this.props.formSettings[key])
		}

		// フォーム項目の数分、空の配列生成
		var inputs = []
		// エラー対象項目
		var columnErrState = []
		// エラー発生項目またはグループ
		var errorMsg = []

		settings.map( setting => {

			// 入力/選択値の初期値
			// チェックボックスはCheckedステートの管理を兼ねてtrue or false
			if( setting.type === "checkbox" ){
				const options = []
				const errFlgs = []
				setting.options.map( option => {
					options[option.id] = false 
					errFlgs[option.id] = false
					return null
				})
				inputs[setting.id] = options
				columnErrState[setting.id] = errFlgs

			// 複数テキスト入力項目
			}else if( Array.of( "text-group-flex", "text-group-vertical" ).includes(setting.type) ){
				const groups = []
				const errFlgs = []
				setting.groups.map( group => {
					const defaultValue = group.default ? group.default : ""
					groups[group.id] = defaultValue
					errFlgs[group.id] = false
					return null
				})
				inputs[setting.id] = groups
				columnErrState[setting.id] = errFlgs

			}else{
				const defaultValue = setting.default ? setting.default : ""
				inputs[setting.id] = defaultValue
				columnErrState[setting.id] = false
			}
			// エラーメッセージの初期値
			errorMsg[setting.id] = null
			return null
		})

		this.state = {
			settings: settings,
			defaultValues: inputs,
			defaultErrorMsg: errorMsg,
			defaultColumnErrState: columnErrState,
			values: inputs,
			errorMsg: errorMsg,
			columnErrState: columnErrState,
			privacyPolicy: false
		}
		this.handleChange.bind(this)
		this.handleChecked.bind(this)
		this.handleClick.bind(this)
	}

	componentDidMount(){
		// 修正画面から渡された値があれば代入
		if( this.props.values ) this.setState({ values : this.props.values })
	}

	// テキスト入力項目用
	handleChange (value, setting, groupId = null ){

		const newValues = Object.assign({}, this.state.values)
		const newColumnErrState = Object.assign({}, this.state.columnErrState)
		const newErrorMsg = Object.assign({}, this.state.errorMsg)

		// 入力値の保存		
		if( groupId ){
			newValues[setting.id][groupId] = value
			newColumnErrState[setting.id][groupId] = false
		}else{
			newValues[setting.id] = value
			newColumnErrState[setting.id] = false
		}

		// 入力値のトリム
		newValues[setting.id] = valueFilter( newValues[setting.id], setting )
		// エラーメッセージの初期化
		newErrorMsg[setting.id] = null

		this.setState({
			values : newValues,
			errorMsg : newErrorMsg,
			columnErrState : newColumnErrState,
		})
	}

	// チェックボックス用
	handleChecked(value, optionId, setting){
		// 入力値を保存
		const newValues = Object.assign({}, this.state.values)
		newValues[setting.id][optionId] = value
		// エラーの初期化
		const newErrorMsg = Object.assign({}, this.state.errorMsg)
		newErrorMsg[setting.id] = null
		this.setState({
			values : newValues,
			errorMsg : newErrorMsg,
		})
	}

	// 住所自動取得
	complementAddress = () => {
		// 郵便番号が正しく入力されてないなら処理しない
		if( this.state.values.zipcode.length !== 8 ) return null
		const { AjaxZip3 } = window
		AjaxZip3.zip2addr('zipcode', '', 'pref-auto', 'city-auto')
	}
	
	// 住所反映
	onBlurZipcode(){
		const newValues = Object.assign({}, this.state.values)
		newValues.prefecture = this.prefauto.value
		newValues.city = this.cityauto.value
		const newErrorMsg = Object.assign({}, this.state.errorMsg)
		newErrorMsg.prefecture = null
		newErrorMsg.city = null
		const newColumnErrState = Object.assign({}, this.state.columnErrState)
		newColumnErrState.prefecture = null
		newColumnErrState.city = null
		this.setState({
			values : newValues,
			errorMsg : newErrorMsg,
			columnErrState : newColumnErrState,
		})
  }

	// 「入力内容を確認するボタン」
	handleClick(){
		// 全項目のチェック
		const result = allValidationCheck(this.state.values, this.props.formSettings)
		this.setState({
			errorMsg : result.errors,
			columnErrState : result.columnErrState,
		})

		// バリデーションエラー時
		if( !result.validCheck ){
			let firsterr
			for ( var key in result.errors ){
				if( result.errors[key] ){
					firsterr = key
					break
				}
			}
			alert("入力内容に誤りがあります。")
			scrollTo('#'+firsterr)
			return false
		}

		// 確認画面に遷移
		navigate(
			this.props.submitPath,
			{
				state: { 
					pathname: this.props.pathname,
					values: this.state.values,
					formSettings: this.props.formSettings
				},
			}
		)
	}

	formReset(){
		this.setState({
			values: this.state.defaultValues,
			errorMsg: this.state.defaultErrorMsg,
			columnErrState: this.state.defaultColumnErrState,
		})
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}


	renderInputs( setting ){
		
		switch ( setting.type ) {
			case ( 'checkbox' ):
				return (
					<> 
					{ setting.options.map( option => 
						<Form.Check 
							key={`${setting.id}-${option.id}`} 
							label={option.label} 
							type={setting.type} 
							checked={this.state.values[setting.id][option.id]}
							onChange={()=>this.handleChecked(!this.state.values[setting.id][option.id], option.id, setting)}
						/>
					)}
					</>
				)
			
			case ( 'textarea' ):
				return (
					<Form.Control 
						as="textarea"
						rows="5" 
						maxLength={setting.length}
						value={this.state.values[setting.id]} 
						// 項目のIDとバリデーションタイプを渡す
						onChange={ e => this.handleChange(e.target.value, setting)} 
						className={this.state.columnErrState[setting.id] && "error"}
					/>
				)
			
			case ( 'text-group-flex' ):
				return (
					<div className="inputgroup">
						{ setting.groups.map( group => {
							// ラベル
							const label = group.label ? (
								<Col xs="auto" >
									<p>{group.label}</p>
								</Col>
							) : null
							// 前ラベル
							const labelprepend = group.labelprepend ? (
								<Col xs="auto" >
									<p>{group.labelprepend}</p>
								</Col>
							) : null
							// 後ラベル
							const labelappend = group.labelappend ? (
								<Col xs="auto"><p>{group.labelappend}</p></Col>
							) : null

							const input = 
							<Col className="px-0">
								<Form.Control 
									type={group.type}
									maxLength={group.length} 
									name={group.id}
									placeholder={group.placeholder} 
									value={this.state.values[setting.id][group.id]} 
									onChange={ e => this.handleChange(e.target.value, setting, group.id)}
									className={this.state.columnErrState[setting.id][group.id] && "error"} 
								/>
							</Col>
							
							return (
								<div 
									className="group"
									style={{width:group.width}} 
									key={group.id}>
									<Row className="align-items-center mx-0">
										{label}{labelprepend}{input}{labelappend}
									</Row>
								</div>
							)
						})}
					</div>
				)

			case ( 'text-group-vertical' ):
				return (
					<div className="inputgroup my-2">
						{ setting.groups.map( group => {
							// 前ラベル
							const labelprepend = group.labelprepend ? (
								<Col xs={9}>
									<p>{group.labelprepend}</p>
								</Col>
							) : null
							// 後ラベル
							const labelappend = group.labelappend ? (
								<Col xs="auto"><p>{group.labelappend}</p></Col>
							) : null
							// input
							const input = 
								<Col xs className="px-0">
									<Form.Control 
										type={group.type}
										maxLength={group.length}
										value={this.state.values[setting.id][group.id]} 
										onChange={ e => this.handleChange(e.target.value, setting, group.id)}
										className={this.state.columnErrState[setting.id][group.id] && "error"}
									/>
								</Col>
							
							return (
								<Row className="align-items-center mb-2" key={group.id}>
									{labelprepend}{input}{labelappend}
								</Row>
							)
						})}
					</div>
				)

			case ( 'radio' ):
				return (
					<Form.Row className="align-items-center">
					{setting.options.map( option => {
						const checked = this.state.values[setting.id] === option.label ? "checked" : ""
						return (
							<Col xs="auto" key={option.id}>
								<Form.Check 
									type={setting.type}
									label={option.label}
									onChange={()=>this.handleChange(option.label, setting)}
									checked={checked}
								/>
							</Col>
						)
					})}
					</Form.Row>
				)
				
			default:

				// 郵便番号だけonKeyUp、onBlurイベント付ける
				let handleZip = null
				if( setting.id === "zipcode" ){
					handleZip = { 
						onKeyUp: () => this.complementAddress(),
						onBlur: () => this.onBlurZipcode(),
					}
				}

				return (
					<Form.Control
						style={{width:setting.width}} 
						type={setting.type} 
						maxLength={setting.length} 
						placeholder={setting.placeholder} 
						name={setting.id}
						value={this.state.values[setting.id]} 
						onChange={ e => this.handleChange(e.target.value, setting)} 
						className={this.state.columnErrState[setting.id] && "error"}
						{...handleZip}
					/>
				)
		}
	}
	
	render(){
		const { settings, errorMsg, privacyPolicy } = this.state

		return(
			<Form ref={form => this.form = form}>
				<input type="text" name="pref-auto" className="d-none" ref={input => { this.prefauto = input }}/>
				<input type="text" name="city-auto" className="d-none" ref={input => { this.cityauto = input }}/>
				{ settings.map( setting => {
					const errmsg = errorMsg[setting.id]
					return (
						<Form.Row 
							key={setting.id} 
							id={setting.id}
							className="align-items-top text-left pt-3"
						>
							<Col 
								lg={{ span:'4', order: 'first' }}
								xs={{ span:'auto', order: 'second' }}
							>
								<Form.Label>{setting.label}</Form.Label>
							</Col>
							<Col 
								lg={{ span:'auto', order: 'second' }}
								xs={{ span:'auto', order: 'first' }}
								className={`d-lg-block ${ !setting.required && "d-none" }`}
							>
								<p className={`tag ${ !setting.required && "invisible" }`} >必須</p>
							</Col>
							<Col 
								lg 
								xs="12" 
								className={`mt-lg-0 mt-3 ${ errmsg && "err" }`}
							>
								{ setting.text && <Form.Text>{setting.text}</Form.Text> }
								{ this.renderInputs( setting ) }
								<p className={`errMsg ${ !errmsg && "invisible" }`} >
									<FontAwesomeIcon icon={faExclamationCircle}/> {errmsg}
								</p>
							</Col>
							<Col lg={12}>
								<hr className="dots-hr" />
							</Col>
						</Form.Row>
					)
				})}
				
				<div className="text-center mt-5">
					<p><strong>ウェブサイト上の個⼈情報の取り扱いについて</strong></p>
					<p>
						いただいた個⼈情報は、お問い合わせ及び、採⽤の対応のみに使⽤し、<br className="d-none d-lg-block" />
						他の⽬的に利⽤することはございません。<br className="d-none d-lg-block" />
						弊社の他の個⼈情報に関する取り扱いについては
						「<a href="/privacyPolicy/" target="_blank" rel="noopener noreferrer" >プライバシーポリシー</a>」
						をご覧ください。<br className="d-none d-lg-block" />
						上記事項をご確認の上、ご同意頂ける⽅は下の「同意する」をチェックしてください。
					</p>
					<Form.Check 
						label="同意する"
						type="checkbox"
						className="privacy-agree" 
						checked={this.state.privacyPolicy}
						onChange={()=>this.setState({ privacyPolicy : !this.state.privacyPolicy })}
					/>
					<div className="text-center mt-5">
						<Button 
							variant="info" 
							className="my-5 mr-4" 
							onClick={()=>this.formReset()}
						>
							リセット
						</Button>
						<Button 
							variant="info" 
							className="my-5" 
							disabled={!privacyPolicy}
							onClick={()=>this.handleClick()}
						>
							⼊⼒内容を確認する
						</Button>
					</div>

				</div>
			</Form>			
		)
	}
}

export default CustomForm

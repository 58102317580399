import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import LayoutExtra from "../components/layoutExtra"

import CustomForm from '../components/customForm'

const Contact = ({ location }) => {

	const data = useStaticQuery(graphql`
    query {
			infoIcon: file(relativePath: { eq: "icons/info.svg" }) {
        publicURL
      },
    }
	`)

	// フォーム入力項目
	const formSettings = {
		company: {
			id: "company",
			label: "御社名",
			type: "text",
			length: "30",
			placeholder: "例）株式会社グローバルワイズ",
			required: true,
			valid: "string"
		},
		name: {
			id: "name",
			label: "お名前",
			type: "text-group-flex",
			required: true,
			requiredColumn: "all",
			groups: [
				{
					id: "family",
					type: "text",
					label: "姓",
					length: "20",
					placeholder: "例）⼭⽥",
					valid: "string",
					width: "45%",
				},
				{
					id: "first",
					type: "text",
					label: "名",
					length: "20",
					placeholder: "例）花⼦",
					valid: "string",
					width: "45%",
				}
			]
		},
		furigana: {
			id: "furigana",
			label: "フリガナ",
			type: "text-group-flex",
			required: true,
			requiredColumn: "all",
			groups: [
				{
					id: "family",
					type: "text",
					label: "姓",
					length: "20",
					placeholder: "例）ヤマダ",
					valid: "string",
					width: "45%",
				},
				{
					id: "first",
					type: "text",
					label: "名",
					length: "20",
					placeholder: "例）ハナコ",
					valid: "string",
					width: "45%",
				}
			]
		},
		department: {
			id: "department",
			label: "部署",
			type: "text",
			length: "30",
			placeholder: "例）営業部",
			required: false,
			valid: "string"
		},
		position: {
			id: "position",
			label: "役職",
			type: "text",
			length: "30",
			placeholder: "例）部⻑",
			required: false,
			valid: "string"
		},
		email: {
			id: "email",
			label: "メールアドレス",
			type: "email",
			length: "50",
			placeholder: "例）abcdefg@g-wise.co.jp",
			required: true,
			valid: "email"
		},
		tel: {
			id: "tel",
			label: "電話番号",
			type: "tel",
			length: "12",
			placeholder: "例）0525812600（半角数字のみ）",
			required: false,
			valid: "tel",
		},
		inquery1: {
			id: "inquery1",
			label: "お問い合わせ内容①",
			type: "checkbox",
			options: [
				{ id: "ecochange", label: "EcoChange" },
				{ id: "mcm", label: "GW-MCM System" },
				{ id: "kintia", label: "KINTIA" },
				{ id: "aws", label: "AWS" },
				{ id: "bugyo", label: "奉⾏製品" },
				{ id: "gdata", label: "G DATA" },
				{ id: "other-products", label: "その他製品" },
				{ id: "develop", label: "システム開発" },
				{ id: "none-of-any", label: "その他" }
			],
			text: "お問い合わせの製品・サービスを選択してください。（複数選択可）",
			required: true,
			valid: null
		},
		inquery2: {
			id: "inquery2",
			label: "お問い合わせ内容②",
			type: "checkbox",
			options: [
				{ id: "pamphlet", label: "資料送付を希望する" },
				{ id: "phonecall", label: "担当者からの電話連絡を希望する" },
				{ id: "visit", label: "担当者の訪問を希望する" },
				{ id: "partner", label: "販売代理店・協業を希望する" },
			],
			text: "ご要望を選択してください。（複数選択可）",
			required: false,
			valid: null
		},
		inquery3: {
			id: "inquery3",
			label: "お問い合わせ内容③",
			type: "textarea",
			length: "500",
			text: "⾃由にご記⼊ください。（500字以内）",
			required: false,
			valid: null
		}
	}

	// 修正時に確認画面から渡される値
	let values = null
	if (location.state) values = location.state.values

	return (
		<LayoutExtra>
			<SEO title="お問い合わせ" description="弊社へのお問い合わせはこちらのページよりお送りください。" />
			<div className="page-head">
				<div className="h2-subtitle">
					<img src={data.infoIcon.publicURL} alt="お問い合わせ" />
					<h2>お問い合わせフォーム</h2>
					<p>Inquiry Form</p>
				</div>
				<p>
					弊社へのお問い合わせは、必要事項をご⼊⼒の上、下記フォームよりお送りください。<br className="d-none d-lg-block" />
					24時間受付しておりますが、弊社からの返信は弊社営業時間内とさせていただきます。
				</p>
			</div>
			<CustomForm
				values={values}
				formSettings={formSettings}
				pathname={location.pathname}
				submitPath="/contactSubmit/"
			/>
		</LayoutExtra>
	)
}

export default Contact

// 前後空白・改行削除
export function valueFilter( value, setting ){

	// -----------------------------複数テキスト入力項目-----------------------------
	if( setting.type === 'text-group-flex' || setting.type === 'text-group-vertical' ){
		const valueFiltered = []
		for( let key in value ){
			valueFiltered[key] = value[key].replace(/^[\s　\uFEFF\xA0]+|[\s　\uFEFF\xA0]+$/g, '').trim()
		}
		return valueFiltered

	// ------------------------------単一テキスト項目--------------------------------
	}else{
		const valueFiltered = value.replace(/^[\s　\uFEFF\xA0]+|[\s　\uFEFF\xA0]+$/g, '').trim()
		return valueFiltered
	}
}




export function singleValidationCheck ( value, setting ) {

	// 戻り値　-----------------------------------------------
	//
	// return {
	// 	errorMsg: →エラーメッセージ
	// 	columnErrState: →エラーが起きた項目
	// }
	//
	// -------------------------------------------------------

	let errorMsg = null

	// -----------------------------複数テキスト入力項目-----------------------------
	if( setting.type === 'text-group-flex' || setting.type === 'text-group-vertical' ){

		let newErrState = []

		// （１）必須入力項目チェック
		if( setting.required && setting.requiredColumn === 'all' ){
			
			let errNum = 0
			for( let key in value ){
				if( !value[key] ){
					newErrState[key] = true
					errNum ++
				}else{
					newErrState[key] = false
				} 
			}

			if ( errNum > 0 ){
				errorMsg = "入力してください"
				return {
					errorMsg: errorMsg,
					columnErrState: newErrState
				}
			}
		}

		// （２）バリデーションチェック
		setting.groups.map( group => {
			const result = validationTypeCheck( group.valid, value[group.id] )
			if( result.errFlg ) errorMsg = result.message
			newErrState[group.id] = result.errFlg
			return null
		})

		return {
			errorMsg: errorMsg,
			columnErrState: newErrState
		}
	// -----------------------------複数テキスト入力項目-----------------------------




	// ---------------------------------その他の項目--------------------------------
	}else{

		// （１）必須入力項目チェック
		if( setting.required ){
			switch( setting.type ){

				// チェックボックス
				case( 'checkbox' ):
					// true(選択済み項目)がひとつあればOK
					let errflg = true
					for( let key in value ){
						if( value[key] ) {
							errflg = false
							break;
						}
					}

					if( errflg ) {
						errorMsg = "選択してください"
						return {
							errorMsg: errorMsg,
							columnErrState: true
						}
					}
					break;

					case( 'radio' ):
						if( value === "" ){
							errorMsg = "選択してください"
							return {
								errorMsg: errorMsg,
								columnErrState: true
							}
						}
						break;
					
					default:
						if( value === "" ){
							errorMsg = "入力してください"
							return {
								errorMsg: errorMsg,
								columnErrState: true
							}
						}
			}
		}
		
		// （２）バリデーションチェック
		const result = validationTypeCheck( setting.valid, value )
		return {
			errorMsg: result.message,
			columnErrState: result.errFlg
		}
	}
	// ---------------------------------その他の項目--------------------------------
}



function validationTypeCheck( type, value ){

	var message = null
	var errFlg = false

	// 空の値は精査しない
	if( value === "" ) return { message: message, errFlg: errFlg }

	var regexp

	switch ( type ){

		case ( 'string' ):
			if( typeof(value) !== "string" ){
				message = "入力値が正しくありません"
				errFlg = true
			} 
			break;
		
		case ( 'number' ):
			regexp = /^[0-9]+$/
			if( !regexp.test(value) ){
				message = "半角数字で入力してください"
				errFlg = true
			} 
			break;
		
		case ( 'email' ):
			regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
			if( !regexp.test(value) ){
				message = "正しいメールアドレスを入力してください"
				errFlg = true
			} 
			break;

		case ( 'zipcode' ):
			regexp = /^[0-9]{3}-[0-9]{4}$/
			if( !regexp.test(value) ){
				message = "正しい郵便番号を入力してください"
				errFlg = true
			} 
			break;

		case ( 'tel' ):
			regexp = /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/
			if( !regexp.test(value) ){
				message = "電話番号を入力してください（半角数字のみ）"
				errFlg = true
			} 
			break;

		default:
　		break;
	}

	return {
		message: message,
		errFlg: errFlg
	}
}

export function allValidationCheck ( values, formSettings ) {

	var newErrors = []
	var newErrState = []
	let validCheck = true

	for( var key in values ){
		const value = values[key]
		const setting = formSettings[key]
		const result = singleValidationCheck(value, setting)
		newErrors[key] = result.errorMsg
		newErrState[key] = result.columnErrState
		if( result.errorMsg ) validCheck = false
	}

	return {
		errors: newErrors,
		columnErrState: newErrState,
		validCheck: validCheck,
	}
}

